*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Rubik, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: hsl(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsl(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
  --pf: 259 94% 44%;
  --sf: 314 100% 40%;
  --af: 174 75% 39%;
  --nf: 214 20% 14%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 259 94% 51%;
  --pc: 259 96% 91%;
  --s: 314 100% 47%;
  --sc: 314 100% 91%;
  --a: 174 75% 46%;
  --ac: 174 75% 11%;
  --n: 214 20% 21%;
  --nc: 212 19% 87%;
  --b1: 0 0% 100%;
  --b2: 0 0% 95%;
  --b3: 180 2% 90%;
  --bc: 215 28% 17%;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (hover: hover) {
  .label a:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
  }

  .menu li > :not(ul):not(.menu-title):not(details):active, .menu li > :not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--nc) / var(--tw-text-opacity));
  }

  .table tr.hover:hover, .table tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  }

  .table-zebra tr.hover:hover, .table-zebra tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  text-transform: var(--btn-text-case, uppercase);
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  outline-color: hsl(var(--bc) / 1);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

.btn-circle {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-group > input[type="radio"].btn {
  -webkit-appearance: none;
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.btninput[type="checkbox"], .btninput[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: auto;
}

.btninput[type="checkbox"]:after, .btninput[type="radio"]:after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  opacity: .75;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  position: relative;
}

.checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.collapse:not(td):not(tr):not(colgroup) {
  visibility: visible;
}

.collapse {
  border-radius: var(--rounded-box, 1rem);
  grid-template-rows: auto 0fr;
  width: 100%;
  transition: grid-template-rows .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.collapse-title, .collapse > input[type="checkbox"], .collapse > input[type="radio"], .collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.collapse > input[type="checkbox"], .collapse > input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
}

.collapse[open], .collapse-open, .collapse:focus:not(.collapse-close), :is(.collapse:not(.collapse-close):has( > input[type="checkbox"]:checked), .collapse:not(.collapse-close):has( > input[type="radio"]:checked)) {
  grid-template-rows: auto 1fr;
}

.collapse[open] .collapse-content, .collapse-open .collapse-content, .collapse:focus:not(.collapse-close) .collapse-content, .collapse:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-content, .collapse:not(.collapse-close) input[type="radio"]:checked ~ .collapse-content {
  visibility: visible;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.drawer {
  grid-auto-columns: max-content auto;
  width: 100%;
  display: grid;
  position: relative;
}

.drawer-content {
  grid-row-start: 1;
  grid-column-start: 2;
}

.drawer-side {
  pointer-events: none;
  overscroll-behavior: contain;
  width: 100%;
  height: 100vh;
  scrollbar-width: none;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-row-start: 1;
  grid-column-start: 1;
  place-items: flex-start start;
  height: 100dvh;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.drawer-side::-webkit-scrollbar {
  display: none;
}

.drawer-side > .drawer-overlay {
  cursor: pointer;
  background-color: #0000;
  place-self: stretch stretch;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  position: sticky;
  top: 0;
}

.drawer-side > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.drawer-side > :not(.drawer-overlay) {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  transform: translateX(-100%);
}

[dir="rtl"] .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

.drawer-toggle {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

.drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
}

.drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-end {
  grid-auto-columns: auto max-content;
}

.drawer-end .drawer-toggle ~ .drawer-content {
  grid-column-start: 1;
}

.drawer-end .drawer-toggle ~ .drawer-side {
  justify-items: end;
}

.drawer-end .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

[dir="rtl"] .drawer-end .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(-100%);
}

.drawer-end .drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

@media (hover: hover) {
  .btm-nav > .disabled:hover, .btm-nav > [disabled]:hover {
    pointer-events: none;
    --tw-border-opacity: 0;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-bg-opacity: .1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }

  .btn:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--b3) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }

  .btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }

  .btn-neutral:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--nf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--nf) / var(--tw-bg-opacity));
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-ghost:hover {
    --tw-border-opacity: 0;
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-bg-opacity: .2;
  }

  .btn-outline:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--bc) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--b1) / var(--tw-text-opacity));
  }

  .btn-outline.btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--pc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--sf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--sf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--sc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-accent:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--af) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--af) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--ac) / var(--tw-text-opacity));
  }

  .btn-outline.btn-success:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--su) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--su) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--suc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-info:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--in) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--in) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--inc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--wa) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--wac) / var(--tw-text-opacity));
  }

  .btn-outline.btn-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--er) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--erc) / var(--tw-text-opacity));
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-bg-opacity: .2;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }

  .btninput[type="checkbox"]:checked:hover, .btninput[type="radio"]:checked:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }

  :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(.active):hover, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(.active):hover {
    cursor: pointer;
    background-color: hsl(var(--bc) / .1);
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    outline-offset: 2px;
    outline: 2px solid #0000;
  }
}

.footer {
  grid-auto-flow: row;
  place-items: start;
  gap: 2.5rem 1rem;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  display: grid;
}

.footer > * {
  place-items: start;
  gap: .5rem;
  display: grid;
}

@media (min-width: 48rem) {
  .footer {
    grid-auto-flow: column;
  }

  .footer-center {
    grid-auto-flow: row dense;
  }
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.hero {
  background-position: center;
  background-size: cover;
  place-items: center;
  width: 100%;
  display: grid;
}

.hero > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.indicator {
  width: max-content;
  display: inline-flex;
  position: relative;
}

.indicator :where(.indicator-item) {
  z-index: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  white-space: nowrap;
  position: absolute;
}

.input {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input-group > .input {
  isolation: isolate;
}

.input-group > *, .input-group > .input, .input-group > .textarea, .input-group > .select {
  border-radius: 0;
}

.join {
  border-radius: var(--rounded-btn, .5rem);
  align-items: stretch;
  display: inline-flex;
}

.join :where(.join-item), .join .join-item:not(:first-child):not(:last-child), .join :not(:first-child):not(:last-child) .join-item {
  border-radius: 0;
}

.join .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .join-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.join :where(.join-item:first-child:not(:last-child)), .join :where(:first-child:not(:last-child) .join-item) {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}

.join .join-item:last-child:not(:first-child), .join :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.join :where(.join-item:last-child:not(:first-child)), .join :where(:last-child:not(:first-child) .join-item) {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

:where(.join *) {
  border-radius: inherit;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu {
  flex-flow: column wrap;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.menu :where(li ul) {
  white-space: nowrap;
  margin-left: 1rem;
  padding-left: .5rem;
  position: relative;
}

.menu :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  -webkit-user-select: none;
  user-select: none;
  grid-auto-columns: minmax(auto, max-content) auto max-content;
  grid-auto-flow: column;
  align-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: grid;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: hsl(var(--bc) / .3);
}

.menu :where(li > .menu-dropdown:not(.menu-dropdown-show)) {
  display: none;
}

:where(.menu li) {
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  position: relative;
}

:where(.menu li) .badge {
  justify-self: end;
}

.modal {
  pointer-events: none;
  opacity: 0;
  overscroll-behavior: contain;
  z-index: 999;
  color: inherit;
  background-color: #0000;
  justify-items: center;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  margin: 0;
  padding: 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: grid;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  overscroll-behavior: contain;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 91.6667%;
  max-width: 32rem;
  max-height: calc(100vh - 5em);
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open] {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

:root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open])) {
  overflow: hidden;
}

.navbar {
  padding: var(--navbar-padding, .5rem);
  align-items: center;
  width: 100%;
  min-height: 4rem;
  display: flex;
}

:where(.navbar > *) {
  align-items: center;
  display: inline-flex;
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  background-color: hsl(var(--bc) / .2);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
  position: relative;
  overflow: hidden;
}

.radio {
  --chkbg: var(--bc);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: 9999px;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.range {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  --range-shdw: var(--bc);
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  overflow: hidden;
}

.range:focus {
  outline: none;
}

.rating {
  display: inline-flex;
  position: relative;
}

.rating :where(input) {
  cursor: pointer;
  animation: rating-pop var(--animation-input, .25s) ease-out;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  border-radius: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 2;
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.stats {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  display: inline-grid;
}

:where(.stats) {
  grid-auto-flow: column;
  overflow-x: auto;
}

.steps .step {
  text-align: center;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  min-width: 4rem;
  display: grid;
}

.swap {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.swap input {
  -webkit-appearance: none;
  appearance: none;
}

.swap .swap-on, .swap .swap-indeterminate, .swap input:indeterminate ~ .swap-on, .swap input:checked ~ .swap-off, .swap.swap-active .swap-off, .swap input:indeterminate ~ .swap-off {
  opacity: 0;
}

.swap input:checked ~ .swap-on, .swap-active .swap-on, .swap input:indeterminate ~ .swap-indeterminate {
  opacity: 1;
}

.textarea {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  min-height: 3rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
}

.toggle {
  --tglbg: hsl(var(--b1));
  --handleoffset: 1.5rem;
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
  --togglehandleborder: 0 0;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .5;
  border-radius: var(--rounded-badge, 1.9rem);
  transition: background, box-shadow var(--animation-input, .2s) ease-out;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset, var(--togglehandleborder);
  flex-shrink: 0;
  width: 3rem;
  height: 1.5rem;
}

.btm-nav > :where(.active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-top-width: 2px;
}

.btm-nav > .disabled, .btm-nav > [disabled] {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
  outline-color: hsl(var(--p) / 1);
}

.btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
}

.btn-neutral {
  --tw-border-opacity: 1;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  outline-color: hsl(var(--n) / 1);
}

.btn-neutral.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf) / var(--tw-bg-opacity));
}

.btn.glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-ghost {
  color: currentColor;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
  outline-color: currentColor;
}

.btn-ghost.btn-active {
  --tw-border-opacity: 0;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.btn-outline.btn-success.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.btn-outline.btn-info.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.btn-outline.btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.btn-outline.btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btn-group > input[type="radio"]:checked.btn, .btn-group > .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--p) / 1);
}

.btninput[type="checkbox"]:checked, .btninput[type="radio"]:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btninput[type="checkbox"]:checked:focus-visible, .btninput[type="radio"]:checked:focus-visible {
  outline-color: hsl(var(--p) / 1);
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: unset;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card.image-full :where(figure) {
  border-radius: inherit;
  overflow: hidden;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.checkbox:checked, .checkbox[checked="true"], .checkbox[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(0deg, hsl(var(--chkbg)) 43%, hsl(var(--chkfg)) 43%, hsl(var(--chkfg)) 57%, hsl(var(--chkbg)) 57%);
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  opacity: .2;
  border-color: #0000;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

[dir="rtl"] .checkbox:checked, [dir="rtl"] .checkbox[checked="true"], [dir="rtl"] .checkbox[aria-checked="true"] {
  background-image: linear-gradient(45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

details.collapse {
  width: 100%;
}

details.collapse summary {
  outline-offset: 2px;
  outline: 2px solid #0000;
  display: block;
  position: relative;
}

details.collapse summary::-webkit-details-marker {
  display: none;
}

.collapse:focus-visible, :is(.collapse:has(.collapse-title:focus-visible), .collapse:has( > input[type="checkbox"]:focus-visible), .collapse:has( > input[type="radio"]:focus-visible)) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.collapse:not(.collapse-open):not(.collapse-close) input[type="checkbox"], .collapse:not(.collapse-open):not(.collapse-close) input[type="radio"]:not(:checked), .collapse:not(.collapse-open):not(.collapse-close) .collapse-title {
  cursor: pointer;
}

.collapse:focus:not(.collapse-open):not(.collapse-close):not(.collapse[open]) .collapse-title {
  cursor: unset;
}

:where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  z-index: 1;
}

.collapse-title, :where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  width: 100%;
  min-height: 3.75rem;
  padding: 1rem 3rem 1rem 1rem;
  transition: background-color .2s ease-out;
}

.collapse[open] :where(.collapse-content), .collapse-open :where(.collapse-content), .collapse:focus:not(.collapse-close) :where(.collapse-content), .collapse:not(.collapse-close) :where(input[type="checkbox"]:checked ~ .collapse-content), .collapse:not(.collapse-close) :where(input[type="radio"]:checked ~ .collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open].collapse-arrow .collapse-title:after, .collapse-open.collapse-arrow .collapse-title:after, .collapse-arrow:focus:not(.collapse-close) .collapse-title:after, .collapse-arrow:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-arrow:not(.collapse-close) input[type="radio"]:checked ~ .collapse-title:after {
  --tw-translate-y: -50%;
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

[dir="rtl"] .collapse[open].collapse-arrow .collapse-title:after, [dir="rtl"] .collapse-open.collapse-arrow .collapse-title:after, [dir="rtl"] .collapse-arrow:focus:not(.collapse-close) .collapse-title:after, [dir="rtl"] .collapse-arrow:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after {
  --tw-rotate: 135deg;
}

.collapse[open].collapse-plus .collapse-title:after, .collapse-open.collapse-plus .collapse-title:after, .collapse-plus:focus:not(.collapse-close) .collapse-title:after, .collapse-plus:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-plus:not(.collapse-close) input[type="radio"]:checked ~ .collapse-title:after {
  content: "−";
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay {
  background-color: #0006;
}

.drawer-toggle:focus-visible ~ .drawer-content label.drawer-button {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.join > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.loading {
  pointer-events: none;
  aspect-ratio: 1;
  background-color: currentColor;
  width: 1.5rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

:where(.menu li:empty) {
  background-color: hsl(var(--bc) / .1);
  height: 1px;
  margin: .5rem 1rem;
}

.menu :where(li ul):before {
  background-color: hsl(var(--bc) / .1);
  content: "";
  width: 1px;
  position: absolute;
  top: .75rem;
  bottom: .75rem;
  left: 0;
}

.menu :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  text-align: left;
  border-radius: var(--rounded-btn, .5rem);
  text-wrap: balance;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(summary):not(.active).focus, :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(summary):not(.active):focus, :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):is(summary):not(.active):focus-visible, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active).focus, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active):focus, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):is(summary):not(.active):focus-visible {
  cursor: pointer;
  background-color: hsl(var(--bc) / .1);
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu li > :not(ul):not(.menu-title):not(details):active, .menu li > :not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.menu :where(li > details > summary)::-webkit-details-marker {
  display: none;
}

.menu :where(li > details > summary):after, .menu :where(li > .menu-dropdown-toggle):after {
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  justify-self: end;
  width: .5rem;
  height: .5rem;
  margin-top: -.5rem;
  transition-property: transform, margin-top;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.menu :where(li > details[open] > summary):after, .menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after {
  margin-top: 0;
  transform: rotate(225deg);
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  background-color: #0000004d;
  animation: .2s ease-out modal-pop;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box, .modal[open] .modal-box {
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

.progress::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
}

.progress:indeterminate {
  --progress-color: hsl(var(--bc));
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

.progress::-webkit-progress-bar {
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
}

.progress::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

.radio:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.radio:checked, .radio[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: radiomark var(--animation-input, .2s) ease-out;
  box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
}

.radio:disabled {
  cursor: not-allowed;
  opacity: .2;
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

.range:focus-visible::-webkit-slider-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
}

.range:focus-visible::-moz-range-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
}

.range::-webkit-slider-runnable-track {
  background-color: hsl(var(--bc) / .1);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
}

.range::-moz-range-track {
  background-color: hsl(var(--bc) / .1);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
}

.range::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
  -webkit-appearance: none;
  appearance: none;
  color: hsl(var(--range-shdw));
  --filler-size: 100rem;
  --filler-offset: .6rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.range::-moz-range-thumb {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
  color: hsl(var(--range-shdw));
  --filler-size: 100rem;
  --filler-offset: .5rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
}

.rating input {
  -webkit-appearance: none;
  appearance: none;
}

.rating .rating-hidden {
  background-color: #0000;
  width: .5rem;
}

.rating input:checked ~ input, .rating input[aria-checked="true"] ~ input {
  --tw-bg-opacity: .2;
}

.rating input:focus-visible {
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  transform: translateY(-.125em);
}

.rating input:active:focus {
  animation: none;
  transform: translateY(-.125em);
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select-bordered {
  --tw-border-opacity: .2;
}

.select:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.select-disabled, .select:disabled, .select[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.select-disabled::placeholder, .select:disabled::placeholder, .select[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

[dir="rtl"] .select {
  background-position: 12px calc(1px + 50%), 16px calc(1px + 50%);
}

:where(.stats) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.steps .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: .5rem;
  margin-left: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  width: 2rem;
  height: 2rem;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

.table tr.active, .table tr.active:nth-child(2n), .table-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
}

.table-zebra tr.active, .table-zebra tr.active:nth-child(2n), .table-zebra-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
}

.textarea:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.textarea-disabled, .textarea:disabled, .textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.textarea-disabled::placeholder, .textarea:disabled::placeholder, .textarea[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[dir="rtl"] .toggle {
  --handleoffsetcalculator: calc(var(--handleoffset) * 1);
}

.toggle:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.toggle:checked, .toggle[checked="true"], .toggle[aria-checked="true"] {
  --handleoffsetcalculator: var(--handleoffset);
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[checked="true"], [dir="rtl"] .toggle[aria-checked="true"] {
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
}

.toggle:indeterminate {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

.toggle:disabled {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  opacity: .3;
  --togglehandleborder: 0 0 0 3px hsl(var(--bc)) inset, var(--handleoffsetcalculator) 0 0 3px hsl(var(--bc)) inset;
  background-color: #0000;
}

.artboard.phone {
  width: 320px;
}

.artboard.phone-1.horizontal, .artboard.phone-1.artboard-horizontal {
  width: 568px;
  height: 320px;
}

.artboard.phone-2.horizontal, .artboard.phone-2.artboard-horizontal {
  width: 667px;
  height: 375px;
}

.artboard.phone-3.horizontal, .artboard.phone-3.artboard-horizontal {
  width: 736px;
  height: 414px;
}

.artboard.phone-4.horizontal, .artboard.phone-4.artboard-horizontal {
  width: 812px;
  height: 375px;
}

.artboard.phone-5.horizontal, .artboard.phone-5.artboard-horizontal {
  width: 896px;
  height: 414px;
}

.artboard.phone-6.horizontal, .artboard.phone-6.artboard-horizontal {
  width: 1024px;
  height: 320px;
}

.btm-nav-xs > :where(.active) {
  border-top-width: 1px;
}

.btm-nav-sm > :where(.active), .btm-nav-md > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-lg > :where(.active) {
  border-top-width: 4px;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-wide {
  width: 16rem;
}

.btn-square:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.drawer-open > .drawer-toggle {
  display: none;
}

.drawer-open > .drawer-toggle ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
  overscroll-behavior: auto;
  width: auto;
  display: block;
  position: sticky;
}

.drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay), [dir="rtl"] .drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-open > .drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
}

.indicator :where(.indicator-item) {
  --tw-translate-x: 50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset: 0 0 auto auto;
}

.indicator :where(.indicator-item.indicator-start) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 0;
  right: auto;
}

.indicator :where(.indicator-item.indicator-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 50%;
  right: 50%;
}

.indicator :where(.indicator-item.indicator-end) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: auto;
  right: 0;
}

.indicator :where(.indicator-item.indicator-bottom) {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: auto;
  bottom: 0;
}

.indicator :where(.indicator-item.indicator-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  bottom: 50%;
}

.indicator :where(.indicator-item.indicator-top) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  bottom: auto;
}

.join.join-vertical {
  flex-direction: column;
}

.join.join-vertical .join-item:first-child:not(:last-child), .join.join-vertical :first-child:not(:last-child) .join-item {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.join.join-vertical .join-item:last-child:not(:first-child), .join.join-vertical :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.join.join-horizontal {
  flex-direction: row;
}

.join.join-horizontal .join-item:first-child:not(:last-child), .join.join-horizontal :first-child:not(:last-child) .join-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}

.join.join-horizontal .join-item:last-child:not(:first-child), .join.join-horizontal :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.btn-group .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group-horizontal .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-horizontal .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group-horizontal .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group-vertical .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.card-compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-normal .card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.drawer-open > .drawer-toggle ~ .drawer-side > .drawer-overlay {
  cursor: default;
  background-color: #0000;
}

.join.join-vertical > :where(:not(:first-child)) {
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
}

.join.join-horizontal > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}

.modal-top :where(.modal-box) {
  --tw-translate-y: -2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  width: 100%;
  max-width: none;
}

.modal-middle :where(.modal-box) {
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  width: 91.6667%;
  max-width: 32rem;
}

.modal-bottom :where(.modal-box) {
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  width: 100%;
  max-width: none;
}

.steps-horizontal .step {
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  min-width: 4rem;
}

.steps-horizontal .step:before {
  --tw-translate-y: 0px;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  width: 100%;
  height: .5rem;
  margin-left: -100%;
}

.steps-vertical .step {
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
  min-height: 4rem;
}

.steps-vertical .step:before {
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: .5rem;
  height: 100%;
  margin-left: 50%;
}

[dir="rtl"] .steps-vertical .step:before {
  margin-right: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-\[8\%\] {
  right: 8%;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-\[12\%\] {
  top: 12%;
}

.top-\[60px\] {
  top: 60px;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[99\] {
  z-index: 99;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mr-\[5px\] {
  margin-right: -5px;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-\[15\%\] {
  margin-top: -15%;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[1\.3rem\] {
  margin-bottom: 1.3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[10vw\] {
  margin-left: 10vw;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-\[10vw\] {
  margin-right: 10vw;
}

.mr-\[15\%\] {
  margin-right: 15%;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-\[-0\.2em\] {
  margin-top: -.2em;
}

.mt-\[300px\] {
  margin-top: 300px;
}

.mt-\[350px\] {
  margin-top: 350px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-10 {
  height: 2.5rem;
}

.h-24 {
  height: 6rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-8 {
  height: 2rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[450px\] {
  max-height: 450px;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.min-h-\[200px\] {
  min-height: 200px;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-6\/12 {
  width: 50%;
}

.w-8 {
  width: 2rem;
}

.w-\[10\.875rem\] {
  width: 10.875rem;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[18rem\] {
  min-width: 18rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[-1\] {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-up {
  animation: 1s both fade-up;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.whitespace-normal {
  white-space: normal;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[1\.25rem\] {
  border-radius: 1.25rem;
}

.rounded-\[1\.2rem\] {
  border-radius: 1.2rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-medium-blue {
  --tw-border-opacity: 1;
  border-color: rgb(3 164 203 / var(--tw-border-opacity));
}

.border-neutral-100\/70 {
  border-color: #f5f5f5b3;
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-200\/60 {
  border-color: #e5e5e599;
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-opacity-30 {
  --tw-border-opacity: .3;
}

.bg-\[\#01203D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 32 61 / var(--tw-bg-opacity));
}

.bg-\[\#084E8E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 78 142 / var(--tw-bg-opacity));
}

.bg-\[\#086E8E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 110 142 / var(--tw-bg-opacity));
}

.bg-\[\#153B5E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 59 94 / var(--tw-bg-opacity));
}

.bg-\[\#1AA3C1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 163 193 / var(--tw-bg-opacity));
}

.bg-\[\#20588C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(32 88 140 / var(--tw-bg-opacity));
}

.bg-\[\#5BC8E0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(91 200 224 / var(--tw-bg-opacity));
}

.bg-\[\#f5f5f5\], .bg-ades-white {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-base-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-bondi-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(3 151 187 / var(--tw-bg-opacity));
}

.bg-dark-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(33 115 150 / var(--tw-bg-opacity));
}

.bg-dark-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(128 130 132 / var(--tw-bg-opacity));
}

.bg-medium-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(3 164 203 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-\[url\(\'\/src\/images\/01-100\.jpg\'\)\] {
  background-image: url("01-100.07b126e3.jpg");
}

.bg-\[url\(\'\/src\/images\/02\.webp\'\)\] {
  background-image: url("02.cd4f82f7.webp");
}

.bg-\[url\(\'\/src\/images\/03\.webp\'\)\] {
  background-image: url("03.a0aa00f9.webp");
}

.bg-\[url\(\'\/src\/images\/1bg\.webp\'\)\] {
  background-image: url("1bg.2d76b1f2.webp");
}

.bg-\[url\(\'\/src\/images\/Abdullrahman-Alzamil\.webp\'\)\] {
  background-image: url("Abdullrahman-Alzamil.c3c36725.webp");
}

.bg-\[url\(\'\/src\/images\/Ahmed-Abdel-hadi\.webp\'\)\] {
  background-image: url("Ahmed-Abdel-hadi.89bd2389.webp");
}

.bg-\[url\(\'\/src\/images\/Ammar\.webp\'\)\] {
  background-image: url("Ammar.02bca27c.webp");
}

.bg-\[url\(\'\/src\/images\/Ayman-Abbas\.webp\'\)\] {
  background-image: url("Ayman-Abbas.bf4a00b1.webp");
}

.bg-\[url\(\'\/src\/images\/Fadi-AlSaid\.webp\'\)\] {
  background-image: url("Fadi-AlSaid.3e2b629b.webp");
}

.bg-\[url\(\'\/src\/images\/Hatem-Soliman\.webp\'\)\] {
  background-image: url("Hatem-Soliman.64dd9f63.webp");
}

.bg-\[url\(\'\/src\/images\/Mahdy\.webp\'\)\] {
  background-image: url("Mahdy.6b7513aa.webp");
}

.bg-\[url\(\'\/src\/images\/Manoj\.webp\'\)\] {
  background-image: url("Manoj.67f951ea.webp");
}

.bg-\[url\(\'\/src\/images\/Mohamed-Farouk\.webp\'\)\] {
  background-image: url("Mohamed-Farouk.90e552ed.webp");
}

.bg-\[url\(\'\/src\/images\/Mohamed-Saad\.webp\'\)\] {
  background-image: url("Mohamed-Saad.fa3c9ce4.webp");
}

.bg-\[url\(\'\/src\/images\/Morcos\.webp\'\)\] {
  background-image: url("Morcos.64f77228.webp");
}

.bg-\[url\(\'\/src\/images\/Wissam-AlAdani\.webp\'\)\] {
  background-image: url("Wissam-AlAdani.daa781a6.webp");
}

.bg-\[url\(\'\/src\/images\/badawy\.webp\'\)\] {
  background-image: url("badawy.496c0f1d.webp");
}

.bg-\[url\(\'\/src\/images\/bg-vectors\.webp\'\)\] {
  background-image: url("bg-vectors.0033baaa.webp");
}

.bg-\[url\(\'\/src\/images\/footer_blur\.svg\'\)\] {
  background-image: url("footer_blur.22f48926.svg");
}

.bg-\[url\(\'\/src\/images\/hero-mob\@2x\.webp\'\)\] {
  background-image: url("hero-mob@2x.6be4d619.webp");
}

.bg-\[url\(\'\/src\/images\/highlights-bg-vector\.webp\'\)\] {
  background-image: url("highlights-bg-vector.96b12204.webp");
}

.bg-\[url\(\'\/src\/images\/ipo-hero\.webp\'\)\] {
  background-image: url("ipo-hero.a6378323.webp");
}

.bg-\[url\(\'\/src\/images\/meteb\.webp\'\)\] {
  background-image: url("meteb.94ecf457.webp");
}

.bg-\[url\(\'\/src\/images\/montasser\.webp\'\)\] {
  background-image: url("montasser.5f089391.webp");
}

.bg-\[url\(\'\/src\/images\/videoBtnNoise\.png\'\)\] {
  background-image: url("videoBtnNoise.54da9a0e.png");
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-\[\#5BC8E0\] {
  --tw-gradient-from: #5bc8e0 var(--tw-gradient-from-position);
  --tw-gradient-to: #5bc8e000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#0E4C78\] {
  --tw-gradient-to: #0e4c78 var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-0 {
  padding: 0;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[1em\] {
  padding-left: 1em;
  padding-right: 1em;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[2\.5rem\] {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-\[2em\] {
  padding-top: 2em;
  padding-bottom: 2em;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[10px\] {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-\[Cairo\] {
  font-family: Cairo;
}

.text-2xl {
  font-size: 2rem;
}

.text-3xl {
  font-size: 3rem;
}

.text-\[1\.2rem\] {
  font-size: 1.2rem;
}

.text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.text-\[4rem\] {
  font-size: 4rem;
}

.text-\[80\%\] {
  font-size: 80%;
}

.text-base {
  font-size: 1rem;
}

.text-sm {
  font-size: .875rem;
}

.text-xl {
  font-size: 1.5rem;
}

.text-xs {
  font-size: .625rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[0\.9\] {
  line-height: .9;
}

.leading-\[1\.5\] {
  line-height: 1.5;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-\[\#041D2C\] {
  --tw-text-opacity: 1;
  color: rgb(4 29 44 / var(--tw-text-opacity));
}

.text-ades-white {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-bondi-blue {
  --tw-text-opacity: 1;
  color: rgb(3 151 187 / var(--tw-text-opacity));
}

.text-dark-grey {
  --tw-text-opacity: 1;
  color: rgb(128 130 132 / var(--tw-text-opacity));
}

.text-light-blue {
  --tw-text-opacity: 1;
  color: rgb(91 200 224 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.animate-delay-\[900ms\] {
  animation-delay: .9s;
}

.animate-duration-\[600ms\] {
  animation-duration: .6s;
}

.animate-once {
  animation-iteration-count: 1;
}

.animate-ease-in-out {
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
}

body {
  font-feature-settings: "liga" off;
  font-family: Rubik;
}

.logo-header {
  max-width: 120px;
}

@media (min-width: 1280px) {
  .logo-header {
    max-width: 200px;
  }
}

p {
  font-family: Cairo;
}

main {
  overflow-x: hidden;
}

@media (min-width: 1440px) and (max-width: 1536px) {
  #drill-h1 {
    font-size: 5.8rem;
  }

  #drill-h1 + p {
    font-size: 1.5rem;
  }

  [dir="rtl"] #drill-h1 {
    font-size: 5rem;
    line-height: 1.1;
  }

  [dir="rtl"] #drill-h1 + p {
    font-size: 1.3rem;
  }
}

#nav.menu {
  background: linear-gradient(276deg, #03a4cb 8%, #0e4c78 100%);
  justify-content: center;
}

#nav.menu li {
  max-width: 85vw;
}

@media (min-width: 1280px) {
  #nav.menu li {
    max-width: 70vw;
  }

  body.rtl #nav.menu li {
    margin-right: 12vw;
  }

  body.ltr #nav.menu li {
    margin-left: 12vw;
  }
}

#nav.menu li a {
  letter-spacing: .02rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-bottom-width: 1px;
  border-radius: 0;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}

:is([dir="ltr"] #nav.menu li a) {
  margin-left: 1rem;
  padding-left: 0;
}

:is([dir="rtl"] #nav.menu li a) {
  margin-right: 1rem;
  padding-right: 0;
}

@media (min-width: 1280px) {
  #nav.menu li a {
    font-size: 2rem;
  }
}

@media (max-width: 376px) {
  #nav.menu li a {
    font-size: 14px;
  }

  #close-menu {
    top: 5%;
  }
}

#nav.menu li a:hover {
  outline-offset: 0px;
  background-color: #0000;
  outline-width: 0;
  outline-style: none;
}

#smooth-content {
  will-change: transform;
}

footer > * {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.sections {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  position: relative;
}

@media (min-width: 1440px) {
  .sections {
    padding-left: 0;
    padding-right: 0;
  }
}

.containers {
  width: 100%;
}

@media (min-width: 640px) {
  .containers {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .containers {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .containers {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .containers {
    max-width: 1280px;
  }
}

@media (min-width: 1440px) {
  .containers {
    max-width: 1440px;
  }
}

@media (min-width: 1536px) {
  .containers {
    max-width: 1536px;
  }
}

.containers {
  margin-left: auto;
  margin-right: auto;
}

.faq-title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (min-width: 1280px) {
  :is([dir="ltr"] .faq-answer) {
    padding-right: 5rem;
  }

  :is([dir="rtl"] .faq-answer) {
    padding-left: 5rem;
  }
}

.faq-answer {
  min-height: 250px;
}

:is([dir="rtl"] #faq-alpine .faq-answer > p) {
  font-size: .875rem;
  line-height: 1.75rem;
}

@media (min-width: 1280px) {
  :is([dir="ltr"] #faq-alpine .faq-answer > p) {
    padding-left: 3rem;
  }

  :is([dir="rtl"] #faq-alpine .faq-answer > p) {
    padding-right: 3rem;
    font-size: 1rem;
  }
}

#faq-alpine button {
  font-size: 14px;
}

@media (min-width: 1280px) {
  #faq-alpine button {
    font-size: 1rem;
  }
}

.bg-gradient-blue {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #5bc8e0 var(--tw-gradient-from-position);
  --tw-gradient-to: #5bc8e000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #0397bb var(--tw-gradient-to-position);
}

.bg-highlights {
  background: linear-gradient(#5bc8e0 0%, #0e4c78 100%);
}

.min-h-screen {
  min-height: 100svh;
}

.grid-document, .grid-numbers {
  gap: 2rem 1rem;
}

@media (min-width: 1280px) {
  .grid-document, .grid-numbers {
    column-gap: 2rem;
  }
}

@media (min-width: 1440px) {
  .grid-document, .grid-numbers {
    column-gap: 2rem;
  }
}

.grid-document, .grid-numbers {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
}

.grid-advisors {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 2rem;
}

.number-items {
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: .3;
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #5bc8e0 var(--tw-gradient-from-position);
  --tw-gradient-to: #5bc8e000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #0397bb var(--tw-gradient-to-position);
  border-radius: 1rem;
  flex-flow: column wrap;
  align-items: flex-start;
  padding: 1.5rem;
  display: flex;
}

.number-items:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 151 187 / var(--tw-bg-opacity));
}

.number-items {
  grid-row: span 1 / span 1;
  grid-column-start: span 1;
}

.sub-item {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  place-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: grid;
}

#howto.sub-item {
  padding-top: 6rem;
  padding-bottom: 4rem;
}

@media (min-width: 1280px) {
  #howto.sub-item {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }

  .sub-item {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.number-items > h4 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

@media (min-width: 1024px) {
  .number-items > h4 {
    font-size: 2rem;
  }
}

.sub-item > p {
  grid-column: span 2 / span 2;
  line-height: 1;
}

:is([dir="rtl"] .sub-item > p) {
  line-height: 1.25;
}

@media (min-width: 1280px) {
  .sub-item > p {
    grid-column: span 2 / span 2;
  }
}

.sub-item > img {
  justify-self: end;
}

.document-items {
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: .3;
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(30px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-image: url("noise.650c16f1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.5rem;
  grid-row: span 1 / span 1;
  grid-column-start: span 1;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  min-height: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
}

.doc-title {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

:is([dir="rtl"] .doc-title) {
  font-size: 1rem;
  line-height: 1.25;
}

@media (min-width: 1280px) {
  .doc-title {
    font-size: 1.2rem;
  }
}

.advisor {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
  flex-direction: column;
  grid-row: span 1 / span 1;
  grid-column-start: span 1;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 200px;
  min-height: 236px;
  max-height: 236px;
  display: flex;
}

.advisor img {
  align-self: center;
}

.details p {
  --tw-text-opacity: 1;
  color: rgb(128 130 132 / var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 500;
}

.details a {
  --tw-text-opacity: 1;
  color: rgb(3 164 203 / var(--tw-text-opacity));
  font-size: 1rem;
}

@media (min-width: 1280px) {
  :is([dir="rtl"] .details a) {
    font-size: 10px;
  }
}

@media (min-width: 1440px) {
  :is([dir="rtl"] .details a) {
    margin-left: .25rem;
  }
}

@media (min-width: 1536px) {
  :is([dir="rtl"] .details a) {
    font-size: 12px;
  }
}

.details a[href^="mailto:"]:after, .details a[target^="_blank"]:after {
  content: "";
  background-color: #03a4cb;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  display: inline-flex;
  -webkit-mask-image: url("arrow-right.2b48ccd2.svg");
  mask-image: url("arrow-right.2b48ccd2.svg");
}

:is([dir="rtl"] .details a[href^="mailto:"]):after, :is([dir="rtl"] .details a[target^="_blank"]):after {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media screen and (max-width: 991px) {
  .grid-document, .grid-advisors, .grid-numbers {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid-document, .grid-advisors, .grid-numbers {
    grid-template-columns: 1fr;
  }
}

.slick-slide {
  margin: 0 .5rem;
}

.slick-list {
  margin: 0 -.5rem;
}

@media (min-width: 768px) {
  .slick-slide {
    margin: 0 1.5rem;
  }

  .slick-list {
    margin: 0 -1.5rem;
  }
}

.slick-left svg, .slick-right svg {
  width: 2.5rem;
  height: 2.5rem;
}

@media (min-width: 1280px) {
  .slick-left svg, .slick-right svg {
    width: 4rem;
    height: 4rem;
  }
}

.subtitles {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 1280px) {
  .subtitles {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.subtitles h3 {
  grid-column: 1 / span 8;
}

@media (min-width: 1280px) {
  .subtitles h3 {
    grid-column: 2 / span 9;
  }
}

.subtitles .arrows {
  grid-column: span 3 / span 3;
}

:is([dir="rtl"] .subtitles .arrows) {
  grid-column: span 1 / span 1;
}

.btn-leaders {
  width: 4rem;
  height: 4rem;
}

.btn-leaders:hover {
  cursor: pointer;
}

:is([dir="rtl"] .btn-leaders) {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 0;
  right: auto;
}

@media (min-width: 1280px) {
  .btn-leaders {
    width: 4rem;
    height: 4rem;
  }
}

@media (min-width: 1440px) {
  .btn-leaders {
    width: 5rem;
    height: 5rem;
  }
}

.btn-leaders {
  border-radius: 1.25rem 0;
}

.btn-leaders img {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.card {
  background-position: 50% 30%;
  position: relative;
}

.card-body {
  flex-wrap: wrap;
  place-content: flex-start flex-end;
  min-height: 360px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
  position: relative;
}

@media (min-width: 1440px) {
  .card-body {
    min-height: 420px;
  }
}

.overlaybg {
  background: linear-gradient(#0000 0%, #0009 80%);
  border-radius: 1.25rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.info {
  min-height: 55px;
}

@media (min-width: 1280px) {
  .info {
    min-height: 72px;
  }
}

.modal-box > p:first-of-type {
  padding-top: 1rem;
}

.modal-box p {
  padding-bottom: 1rem;
  font-size: .875rem;
}

@media (min-width: 1024px) {
  .modal-box p {
    font-size: 1rem;
  }
}

.bioNames {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
}

:is([dir="rtl"] .bioNames) {
  max-width: 12rem;
  line-height: 1.25;
}

@media (min-width: 1024px) {
  .bioNames {
    font-size: 1.5rem;
  }
}

@media (min-width: 1280px) {
  :is([dir="rtl"] .bioNames) {
    max-width: 24rem;
  }
}

.bioPos {
  font-weight: 500;
}

#leadersBios > dialog > form.modal-box {
  padding: 3rem 2rem 2rem;
}

#leadersBios > dialog > form.modal-box > .btn-ghost {
  top: 1rem;
}

:is([dir="ltr"] #leadersBios > dialog > form.modal-box > .btn-ghost) {
  right: 1rem;
}

:is([dir="rtl"] #leadersBios > dialog > form.modal-box > .btn-ghost) {
  left: 1rem;
  right: auto;
}

.btn-neutral {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 400;
}

#disclaimers > dialog.modal {
  -webkit-user-select: none;
  user-select: none;
  background-color: #03a4cb80;
}

#disclaimers > dialog > .modal-box {
  max-height: 80vh;
}

@media (min-width: 1280px) {
  #disclaimers > dialog > .modal-box {
    max-height: calc(100vh - 5em);
  }
}

#disclaimers > dialog > .modal-box h1 {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(3 151 187 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
}

@media (min-width: 1280px) {
  #disclaimers > dialog > .modal-box h1 {
    font-size: 2rem;
  }
}

#disclaimers > dialog > .modal-box h3 {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

:is([dir="rtl"] #disclaimers > dialog > .modal-box h3) {
  font-size: 1.3rem;
}

:is([dir="ltr"] .agree-btn-yes) {
  margin-right: 1rem;
}

:is([dir="rtl"] .agree-btn-yes) {
  margin-left: 1rem;
}

#disclaimers > dialog > .modal-box p, #disclaimers > dialog > .modal-box ul li {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-family: Cairo;
  font-size: .875rem;
}

@media (min-width: 1280px) {
  #disclaimers > dialog > .modal-box p, #disclaimers > dialog > .modal-box ul li {
    font-size: 1rem;
  }
}

#disclaimers .select:focus {
  outline-offset: 0;
  outline-width: 0;
}

#disclaimers .btn {
  border-radius: 1.25rem;
  min-width: 8rem;
}

#disclaimers .ivh {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

#disclaimers .ivh li {
  padding-right: 0;
}

.hero-bg {
  background-color: #00000073;
}

.parallax {
  background-blend-mode: multiply;
  background-color: #00000073;
}

@media (min-width: 1024px) {
  .parallax {
    background-color: #0000;
  }
}

.parallax {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

.bg {
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cards {
  width: 100%;
}

.cards .card-item {
  --tw-bg-opacity: 1;
  background-color: rgb(33 115 150 / var(--tw-bg-opacity));
  background-image: url("card-noise.1978aa1f.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.25rem;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: baseline;
  min-height: 280px;
  margin-bottom: 2rem;
  padding: 2rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

@media (min-width: 1280px) {
  .cards .card-item {
    min-height: 390px;
  }
}

.cards .card-item {
  width: 100%;
}

.cards .card-item:first-child {
  box-shadow: 0 0 30px 3px #0000000d;
}

.cards .card-item > h1 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
  font-size: 3rem;
  line-height: 1;
}

:is([dir="rtl"] .cards .card-item > h1) {
  align-self: flex-start;
  align-items: flex-end;
  display: flex;
}

@media (min-width: 1440px) {
  .cards .card-item > h1 {
    font-size: 6rem;
  }
}

.cards .card-item > p {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
  font-family: Rubik;
  font-size: 1rem;
  line-height: 1;
}

:is([dir="rtl"] .cards .card-item > p) {
  text-align: right;
  align-self: flex-start;
  line-height: 1.25;
}

@media (min-width: 1280px) {
  .cards .card-item > p {
    font-size: 1.3rem;
  }
}

.faq-item {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border: 1px solid #ffffff52;
  border-radius: 1.25rem;
}

#faq-alpine p {
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Cairo;
}

.lang-select > a {
  font-size: 11px;
}

@media (min-width: 1024px) {
  .lang-select > a {
    font-size: 1rem;
  }
}

.navbar {
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  display: flex;
  position: absolute;
}

.site-logo {
  color: #000;
  font-size: 18px;
}

.name {
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-family: Rubik;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
}

:is([dir="rtl"] .name) {
  max-width: 12rem;
  line-height: 1.25;
}

@media (min-width: 1280px) {
  :is([dir="rtl"] .name) {
    max-width: 15rem;
  }
}

@media (min-width: 1440px) {
  .name {
    font-size: 1.3rem;
  }
}

.position {
  --tw-text-opacity: 1;
  color: rgb(91 200 224 / var(--tw-text-opacity));
  font-family: Rubik;
  font-size: .875rem;
  line-height: 1;
}

:is([dir="rtl"] .position) {
  max-width: 12rem;
  font-size: 1rem;
  line-height: 1.25;
}

.slick-arrow > svg {
  fill: #808284;
  stroke: #808284;
}

.slick-arrow.slick-disabled > svg {
  fill: none;
}

.gs_reveal {
  will-change: transform, opacity;
}

#scroll-timeline {
  margin-top: -10px;
}

:is([dir="rtl"] #scroll-timeline) {
  margin-top: 1rem;
}

@media (min-width: 1280px) {
  #scroll-timeline {
    margin-top: -2rem;
  }

  :is([dir="rtl"] #scroll-timeline) {
    margin-top: -.25rem;
  }
}

.panel {
  --tw-bg-opacity: .5;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  aspect-ratio: .97;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 320px;
  min-height: 325px;
  padding: 2rem;
  display: flex;
}

h3.day {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}

@media (min-width: 1280px) {
  h3.day {
    font-size: 3rem;
  }
}

h3.month {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
}

@media (min-width: 1280px) {
  h3.month {
    font-size: 2rem;
  }

  .panel-description {
    min-height: 72px;
  }
}

.panel-description p {
  font-family: Cairo;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (min-width: 1280px) {
  .panel-description p {
    font-size: 1.25rem;
  }
}

.panel-icon {
  align-self: flex-end;
}

#howto.grid {
  padding-top: 6rem;
  padding-bottom: 4rem;
}

@media (min-width: 1280px) {
  #howto.grid {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }
}

.nav-container {
  visibility: hidden;
  transition: all .3s;
}

#menu-toggle-btn {
  z-index: 100000;
  cursor: pointer;
  transition: all .3s;
}

span.span-menu {
  background: #000;
  width: 24px;
  height: 2px;
  transition: all .3s;
  display: inline-block;
  position: absolute;
  top: 50%;
}

span.span-menu:before {
  content: " ";
  background: #000;
  width: 24px;
  height: 2px;
  transition: all .3s;
  display: inline-block;
  position: absolute;
  top: -2px;
  transform: translateY(-4px);
}

.active span.span-menu {
  background: #fff;
  transform: rotate(45deg);
}

.active span.span-menu:before {
  top: unset;
  background: #fff;
  transform: rotate(-90deg);
}

.active:hover span.span-menu:before {
  top: unset;
}

.nav-item-wrapper:after {
  content: "";
  background: none;
  width: 500px;
  height: 60px;
  margin: 0 auto;
  transition: all 1s;
  position: absolute;
  top: 60px;
  left: 0;
}

#ipo {
  background-image: linear-gradient(#5bc8e0 0%, #0397bb 100%);
}

.background-animate {
  background-size: 400%;
  animation: 3s infinite AnimationName;
}

@keyframes AnimationName {
  0%, 100% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }
}

svg {
  overflow: visible !important;
}

.parallax-section {
  transform-origin: top;
  width: 100%;
  height: 100vh;
  padding: 0 1rem;
  top: 0;
  overflow: hidden;
}

.parallax-image {
  object-fit: cover;
  will-change: transform;
  width: 100%;
  height: 200vh;
}

.logo-section {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.logo-section svg {
  width: 60%;
}

.grid-section {
  z-index: 2;
  width: 100%;
  overflow: visible;
}

.grid-layout {
  grid-gap: 1rem;
  transform-origin: top;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  display: grid;
}

.column {
  height: 100%;
}

.column-content {
  grid-gap: 1rem;
  grid-template-rows: 45vh 45vh 45vh;
  height: 100%;
  display: grid;
}

.grid-image {
  width: 100%;
}

.grid-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pin-section {
  grid-row-gap: 1rem;
  grid-template-rows: 1fr 1fr;
  height: 100vh;
  display: grid;
  overflow: hidden;
}

.pin-content {
  grid-column-gap: 1rem;
  will-change: transform;
  grid-template-columns: 1fr 1fr 1fr;
  width: 300%;
  display: grid;
}

.pin-box {
  width: 100%;
  height: 100%;
}

.pin-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.spacer {
  justify-content: center;
  align-items: center;
  height: 20vh;
  display: flex;
}

.scroll-letter {
  fill: #fff;
}

.smoother-letter, #mouse-wheel {
  fill: #88ce02;
}

.by-greensock-letter {
  fill: #fff;
  opacity: .6;
}

#mouse-outline {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 13px;
}

.ivh {
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Cairo;
  list-style-type: disc;
  list-style-position: outside;
}

@media (min-width: 1024px) {
  .ivh {
    columns: 2;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .ivh-1 {
    columns: 1;
  }
}

.ivh li {
  margin-bottom: .75rem;
}

@media (min-width: 1024px) {
  :is([dir="ltr"] .ivh li) {
    padding-right: 2rem;
  }

  :is([dir="rtl"] .ivh li) {
    padding-left: 2rem;
  }
}

.f-header {
  flex-direction: column;
  align-items: flex-start;
  max-width: 280px;
  display: flex;
}

@media (min-width: 768px) {
  .f-header {
    max-width: 36rem;
  }
}

@media (min-width: 1024px) {
  .f-header {
    max-width: 48rem;
  }
}

@media (min-width: 1280px) {
  .f-header {
    flex-direction: row;
    align-items: center;
    max-width: 56rem;
  }
}

.f-number {
  --tw-text-opacity: 1;
  color: rgb(91 200 224 / var(--tw-text-opacity));
  font-size: 2.2rem;
  font-weight: 700;
}

:is([dir="ltr"] .f-number) {
  margin-right: .5rem;
}

:is([dir="rtl"] .f-number) {
  margin-left: .5rem;
}

@media (min-width: 1024px) {
  :is([dir="ltr"] .f-number) {
    margin-left: .5rem;
    margin-right: 1rem;
  }

  :is([dir="rtl"] .f-number) {
    margin-left: 1rem;
    margin-right: .5rem;
  }
}

@media (min-width: 1280px) {
  .f-number {
    margin-left: 18px;
    margin-right: 2rem;
    font-size: 2.4rem;
  }

  :is([dir="rtl"] .f-number) {
    margin-left: 2rem;
    margin-right: 18px;
  }
}

@media (min-width: 1440px) {
  .f-number {
    font-size: 2.5rem;
  }
}

.f-title {
  font-size: 1.1rem;
}

:is([dir="ltr"] .f-title) {
  line-height: 1;
}

:is([dir="rtl"] .f-title) {
  line-height: 1.25;
}

@media (min-width: 1280px) {
  .f-title {
    font-size: 1.2rem;
    font-weight: 500;
  }

  :is([dir="ltr"] .f-title) {
    padding-right: 3rem;
  }
}

@media (min-width: 1440px) {
  .f-title {
    font-size: 1.25rem;
  }
}

h2 > button {
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border: 1px solid #ffffff52;
  border-radius: 1.25rem;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-dark-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgb(33 115 150 / var(--tw-border-opacity));
}

.hover\:bg-dark-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(33 115 150 / var(--tw-bg-opacity));
}

.hover\:bg-medium-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 164 203 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-neutral-200\/60:focus {
  --tw-ring-color: #e5e5e599;
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.active\:bg-white:active {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes wiggle {
  0%, 100% {
    transform: rotate(-3deg);
  }

  50% {
    transform: rotate(3deg);
  }
}

.group:hover .group-hover\:animate-wiggle {
  animation: .3s ease-in-out infinite wiggle;
}

.group:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:is([dir="ltr"] .ltr\:-ml-\[5px\]) {
  margin-left: -5px;
}

:is([dir="ltr"] .ltr\:mr-\[15\%\]) {
  margin-right: 15%;
}

:is([dir="ltr"] .ltr\:text-left) {
  text-align: left;
}

:is([dir="ltr"] .ltr\:leading-none) {
  line-height: 1;
}

:is([dir="rtl"] .rtl\:ml-\[15\%\]) {
  margin-left: 15%;
}

:is([dir="rtl"] .rtl\:flex-row-reverse) {
  flex-direction: row-reverse;
}

:is([dir="rtl"] .rtl\:text-right) {
  text-align: right;
}

:is([dir="rtl"] .rtl\:leading-tight) {
  line-height: 1.25;
}

@media (min-width: 640px) {
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }
}

@media (min-width: 768px) {
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-start-10 {
    grid-column-start: 10;
  }

  .md\:min-h-\[35vh\] {
    min-height: 35vh;
  }

  .md\:text-4xl {
    font-size: 5.5rem;
  }

  .md\:text-5xl {
    font-size: 7rem;
  }
}

@media (min-width: 1024px) {
  .lg\:order-first {
    order: -9999;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:justify-items-stretch {
    justify-items: stretch;
  }

  .lg\:bg-\[url\(\'\/src\/images\/hero_background\.webp\'\)\] {
    background-image: url("hero_background.c232bfe1.webp");
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:text-\[4rem\] {
    font-size: 4rem;
  }

  .lg\:text-\[9rem\] {
    font-size: 9rem;
  }
}

@media (min-width: 1280px) {
  .xl\:visible {
    visibility: visible;
  }

  .xl\:order-first {
    order: -9999;
  }

  .xl\:order-last {
    order: 9999;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-start-10 {
    grid-column-start: 10;
  }

  .xl\:col-start-11 {
    grid-column-start: 11;
  }

  .xl\:col-start-2 {
    grid-column-start: 2;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:col-start-8 {
    grid-column-start: 8;
  }

  .xl\:-ml-\[5px\] {
    margin-left: -5px;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:ml-3 {
    margin-left: .75rem;
  }

  .xl\:ml-\[17vw\] {
    margin-left: 17vw;
  }

  .xl\:mr-3 {
    margin-right: .75rem;
  }

  .xl\:mr-\[17vw\] {
    margin-right: 17vw;
  }

  .xl\:mt-24 {
    margin-top: 6rem;
  }

  .xl\:mt-\[300px\] {
    margin-top: 300px;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:min-h-0 {
    min-height: 0;
  }

  .xl\:min-h-\[700\] {
    min-height: 700px;
  }

  .xl\:w-\[70\%\] {
    width: 70%;
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:content-start {
    align-content: flex-start;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:justify-normal {
    justify-content: normal;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:bg-\[url\(\'\/src\/images\/hero_background\.webp\'\)\] {
    background-image: url("hero_background.c232bfe1.webp");
  }

  .xl\:bg-none {
    background-image: none;
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:pb-\[10px\] {
    padding-bottom: 10px;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pr-12 {
    padding-right: 3rem;
  }

  .xl\:pt-16 {
    padding-top: 4rem;
  }

  .xl\:pt-24 {
    padding-top: 6rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:text-2xl {
    font-size: 2rem;
  }

  .xl\:text-3xl {
    font-size: 3rem;
  }

  .xl\:text-4xl {
    font-size: 5.5rem;
  }

  .xl\:text-\[1\.2rem\] {
    font-size: 1.2rem;
  }

  .xl\:text-\[1\.3rem\] {
    font-size: 1.3rem;
  }

  .xl\:text-\[1\.5rem\] {
    font-size: 1.5rem;
  }

  .xl\:text-\[4rem\] {
    font-size: 4rem;
  }

  .xl\:text-\[6rem\] {
    font-size: 6rem;
  }

  .xl\:text-xl {
    font-size: 1.5rem;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  :is([dir="ltr"] .ltr\:xl\:-ml-\[5px\]) {
    margin-left: -5px;
  }

  :is([dir="ltr"] .ltr\:xl\:pl-7) {
    padding-left: 1.75rem;
  }

  :is([dir="rtl"] .rtl\:xl\:flex-row-reverse) {
    flex-direction: row-reverse;
  }

  :is([dir="rtl"] .rtl\:xl\:pr-7) {
    padding-right: 1.75rem;
  }
}

@media (min-width: 1440px) {
  .\32 xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .\32 xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .\32 xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .\32 xl\:col-start-2 {
    grid-column-start: 2;
  }

  .\32 xl\:min-h-screen {
    min-height: 100vh;
  }

  .\32 xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .\32 xl\:pb-24 {
    padding-bottom: 6rem;
  }

  .\32 xl\:pb-\[14px\] {
    padding-bottom: 14px;
  }

  .\32 xl\:pl-16 {
    padding-left: 4rem;
  }

  .\32 xl\:pr-16 {
    padding-right: 4rem;
  }

  .\32 xl\:text-2xl {
    font-size: 2rem;
  }

  .\32 xl\:text-3xl {
    font-size: 3rem;
  }

  .\32 xl\:text-4xl {
    font-size: 5.5rem;
  }

  .\32 xl\:text-5xl {
    font-size: 7rem;
  }

  .\32 xl\:text-\[5rem\] {
    font-size: 5rem;
  }

  .\32 xl\:text-\[7rem\] {
    font-size: 7rem;
  }

  .\32 xl\:text-xl {
    font-size: 1.5rem;
  }

  .\32 xl\:leading-\[0\.9\] {
    line-height: .9;
  }
}

@media (min-width: 1536px) {
  .\33 xl\:pl-20 {
    padding-left: 5rem;
  }

  .\33 xl\:pr-20 {
    padding-right: 5rem;
  }

  .\33 xl\:text-\[7\.5rem\] {
    font-size: 7.5rem;
  }

  .\33 xl\:text-\[7\.9rem\] {
    font-size: 7.9rem;
  }
}

/*# sourceMappingURL=ar.98a642a7.css.map */
